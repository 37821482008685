<template>
    <div>
        <div>
            <div class="content-header container-fluid bg-white">
                <div class="row">
                    <div class="col-sm-4">
                        <h4><i class="ti-world"> </i> 物资报废 | 报废记录</h4>
                    </div>
                </div>
            </div>
            <div class="content">
                <div class="row">
                    <div class="col-md-12">
                        <div class="w-block" style="padding: 2px;">
                            <!-- <el-input v-model="grid.sea.KEY" placeholder="请输入关键字" style="width: 300px;margin-left: 5px">
                                <el-button slot="append" icon="el-icon-search" @click="filter"></el-button>
                            </el-input> -->
                            <!-- <el-select v-model="grid.sea.TYPE_ID" @change="filter" clearable placeholder="请选择类型">
                                <el-option v-for="item in typeList" :key="item.Key" :value="item.Key"
                                    :label="item.Value" />
                            </el-select> -->
                            <el-date-picker v-model="grid.sea.START_DT" value-format="yyyy-MM-dd" @change="filter"
                                type="date" placeholder="选择开始日期">
                            </el-date-picker>
                            <el-date-picker v-model="grid.sea.END_DT" value-format="yyyy-MM-dd" @change="filter"
                                type="date" placeholder="选择结束日期">
                            </el-date-picker>
                            <el-select v-model="grid.sea.SEA_STATE" @change="filter" clearable placeholder="请选择状态">
                                <el-option :value="1" label="已审核" />
                                <el-option :value="2" label="未审核" />
                            </el-select>
                            <el-select v-model="grid.sea.CREATED_ID" @change="filter" clearable placeholder="请选择申请人">
                                <el-option v-for="item in createdList" :key="item.Id" :value="item.Id"
                                    :label="item.Name" />
                            </el-select>
                            <!-- <el-select v-model="grid.sea.APPROVE_ID" @change="filter" clearable placeholder="请选择审核人">
                                <el-option v-for="item in approveList" :key="item.Id" :value="item.Id"
                                    :label="item.Name" />
                            </el-select> -->
                        </div>
                        <wgrid :data="grid.ls" v-loading="grid.loading" :total="grid.total"
                            :page-index="grid.sea.PAGE_INDEX + 1" :page-size="grid.sea.PAGE_SIZE"
                            @page-changed="loadPage">
                            <el-table-column prop="THING_NAME" label="名称" width="120" align="center" />
                            <el-table-column prop="CAUSE" label="原因" width="150" align="center" />
                            <el-table-column prop="LOCATION" label="位置" width="150" align="center" />
                            <el-table-column prop="COUNT" label="数量" width="80" align="center" />
                            <el-table-column prop="AMOUNT" label="金额" width="80" align="center" />
                            <el-table-column prop="STATUS" label="状态" width="100" align="center">
                                <template slot-scope="scope">
                                    <el-tag v-if="scope.row.STATUS == 0" type="info"> {{ scope.row.STATUS_TEXT
                                        }}</el-tag>
                                    <el-tag v-else-if="scope.row.STATUS == 1" type="danger">
                                        {{ scope.row.STATUS_TEXT }}</el-tag>
                                    <el-tag v-else-if="scope.row.STATUS == 2" type="success">
                                        {{ scope.row.STATUS_TEXT }}</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column label="照片" width="160" align="center">
                                <template slot-scope="scope">
                                    <el-image style="height: 60px;margin-top: 5px;margin-right: 5px;"
                                        v-for="item in scope.row.IMAGEKVS" :key="item.Key" :src="item.Value">
                                    </el-image>
                                </template>
                            </el-table-column>
                            <el-table-column prop="CREATED_NAME" label="申请人" width="100" align="center" />
                            <el-table-column prop="CREATED_TIME" label="申请日期" width="100" align="center" />

                            <el-table-column prop="APPROVE_NAME" label="审核人" width="100" align="center" />
                            <el-table-column prop="APPROVE_TIME" label="审核日期" width="100" align="center" />
                            <el-table-column prop="NOTE" label="备注" width="150" align="center" />
                            <el-table-column label="操作" width="150">
                                <template slot-scope="scope">
                                    <el-button v-if="whale.hasPower('/loss/power')" type="text" icon="el-icon-back"  style="color: #fda34e;"
                                        @click="rowRevoke(scope.row)">撤销</el-button>
                                    <el-button v-if="scope.row.STATUS != 0" type="text" icon="el-icon-delete" style="color: #f71e1e;"
                                        @click="rowDel(scope.row)">删除</el-button>
                                    <el-button type="text" icon="el-icon-check" v-if="scope.row.STATUS == 0"  style="color: #f30dd4;"
                                        @click="rowApprove(scope.row)">审核</el-button>
                                    <!-- <el-button type="text" icon="el-icon-edit" v-if="scope.row.STATUS != 2"
                                        @click="rowEdit(scope.row)">修改</el-button>
                                    <el-button type="text" icon="el-icon-view" v-if="scope.row.STATUS == 2"
                                        @click="rowDetail(scope.row)">详情</el-button>
                                    <el-button type="text" icon="el-icon-refresh"
                                        v-if="user.ROLE == 'ADMIN' && scope.row.STATUS == 2"
                                        @click="rowUpdate(scope.row)">更新</el-button> -->
                                </template>
                            </el-table-column>
                            <el-table-column />
                        </wgrid>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <Approve ref="info_approve" @on-saved="getList" />
        </div>
    </div>
</template>

<script>
    import Wgrid from "@/components/wgrid";
    import jsFileDownload from 'js-file-download'
    import Approve from "./approve";
    export default {
        name: "index",
        components: { Wgrid, Approve },
        data() {
            return {
                grid: {
                    sea: {
                        PAGE_SIZE: 12,
                        PAGE_INDEX: 0,
                        NAME: ""
                    },
                    ls: [],
                    total: 0,
                    loading: false,
                },
                srcList: [],
                createdList: [],
                approveList: []
            }
        },
        created() {
            this.getList();
            this.getTeachers();
        },
        methods: {
            filter() {
                this.grid.sea.PAGE_INDEX = 0;
                this.grid.ls = [];
                this.getList();
            },
            loadPage(pi) {
                this.grid.sea.PAGE_INDEX = pi - 1;
                this.getList();
            },
            getList() {
                let self = this;
                this.grid.loading = true;
                this.whale.remote.getCollection({
                    url: "/api/School/LOSS/RecordApi/GetList",
                    data: this.grid.sea,
                    completed: function (its, n) {
                        self.grid.ls = its;
                        self.grid.total = n;
                        self.grid.loading = false;
                        // console.log(its)
                        // self.srcList = its.map(x => x.IMAGEKEYS.map(y => y.Value)).flat(Infinity);
                    }
                })
            },
            getTeachers() {
                let self = this;
                this.grid.loading = true;
                this.whale.remote.getResult({
                    url: "/api/School/LOSS/RecordApi/GetTeachers",
                    completed: function (res) {
                        self.createdList = res.DATA.Item1;
                        self.approveList = res.DATA.Item2;
                        self.grid.loading = false;
                    }
                })
            },
            rowDel(r) {
                this.whale.confirmText("真的要删除这条记录吗？", () => {
                    let self = this;
                    this.whale.remote.getResult({
                        url: "/api/School/LOSS/RecordApi/Delete",
                        data: { ID: r.ID },
                        completed: function () {
                            //self.filter();
                            self.getList();
                        }
                    })
                });
            },
            rowRevoke(item) {
                this.whale.confirmText("真的要撤销这条记录吗？", () => {
                    let self = this;
                    this.whale.remote.getResult({
                        url: "/api/School/LOSS/RecordApi/Revoke",
                        data: { ID: item.ID },
                        completed: function () {
                            //self.filter();
                            self.getList();
                        }
                    })
                });
            },
            rowApprove(item) {
                this.$refs.info_approve.init(item);
            }
        }
    }
</script>