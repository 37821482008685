<template>
    <div>
        <el-dialog :visible.sync="sta.show">
            <span slot="title">审核报废记录</span>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label><b>申请老师：{{model.CREATED_NAME}}</b></label>
                    </div>
                    <div class="form-group">
                        <label><b>申请物品：{{model.THING_NAME}}</b></label>
                    </div>
                    <div class="form-group">
                        <label><b>申请理由：{{model.CAUSE}}</b></label>
                    </div>
                    <div class="form-group">
                        <label><b>申请时间：{{model.CREATED_TIME}}</b></label>
                    </div>
                    <div class="form-group">
                        <label>状态</label>
                        <div><el-radio v-model="form.STATUS" label="1">同意</el-radio>
                            <el-radio v-model="form.STATUS" label="2">拒绝</el-radio>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>备注</label>
                        <el-input type="textarea" v-model="form.NOTE"></el-input>
                    </div>
                </div>
            </div>
            <div slot="footer" v-loading="sta.loading">
                <el-button icon="el-icon-close" @click="sta.show=false">取消</el-button>
                <el-button icon="el-icon-check" type="success" @click="submit">确认</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                sta: {
                    show: false,
                    loading: false
                },
                form: {
                    STATUS: 0,
                    NOTE: ""
                },
                model: {},
            }
        },
        methods: {
            init(model) {
                this.sta = { show: true, loading: false }
                this.model = JSON.parse((JSON.stringify(model)));
                this.form.ID = this.model.ID;
            },
            submit() {
                let self = this;
                self.sta.loading = true;
                this.whale.remote.getResult({
                    url: "/api/School/LOSS/RecordApi/Approve",
                    data: self.form,
                    finally() { self.sta.loading = false; },
                    completed: function () {
                        self.sta.show = false;
                        self.$emit("on-saved");
                    }
                })
            }
        }
    }
</script>